<template>
  <topNav class="top" :style="style" v-show="mobile == false"></topNav>  
  <topNavM class="topM" :style="style" v-show="mobile"></topNavM>  
  <router-view/>
  <!-- <rside/> -->
  <foter></foter>
</template>
<script>
  import topNav from './components/topNav';
  import topNavM from './components/topNavM';
  import foter from './components/foter';
  // import rside from './components/rside';
  export default {
    components:{
      topNav,
      topNavM,
      foter,
      // rside,
    },
    data(){
      return{
        list:['home','page'],
        mobile: false
      }
    },
     mounted() {
			window.addEventListener("scroll", this.windowScroll);
       //监听页面滚动
      let w = document.documentElement.offsetWidth || document.body.offsetWidth;
        console.log('w',w);
        if(w < 1000){
          this.mobile = true;
        }
        console.log(this.mobile);
		},
    methods:{
      goPage(e) {
        console.log(e);
        if (e == 0) {
          this.$router.push('/');
        }else if (e == 1) {
          this.$router.push('/prodect');
        }
      }
    }
  }
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
.top{
        position: fixed;
        top: 0;
        z-index: 9999;
  }
.topM{
        position: -webkit-sticky; /* safari 浏览器 */
        position: sticky; /* 其他浏览器 */
        top: 0px;
        z-index: 9999;
  }
</style>
