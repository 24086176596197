import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/product.vue')
  },
  {
    path: '/aS',
    name: 'aS',
    component: () => import(/* webpackChunkName: "about" */ '../views/aSolution.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import(/* webpackChunkName: "about" */ '../views/course.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import(/* webpackChunkName: "about" */ '../views/price.vue')
  },
  {
    path: '/mark',
    name: 'mark',
    component: () => import(/* webpackChunkName: "about" */ '../views/mark.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
  },
  {
    path: '/logIn',
    name: 'logIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/logIn.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
