<template >
<div class="foter" v-show="mobile == false">
  <div class = ' flexCent cf' >
    <div class="logoBox mr80">
      <div class=" flex aIC">
        <img class="logo mr10 click" v-bind:src="logo" alt="" />
        <div class="f16 click ">{{cTxt}}</div>
      </div>
    </div>
    <div class="flex ml80">
      <div class="flex mr30">
        <div v-for="i in fTxt" :key="i" class="mr30">
          <div class="f16">{{i.tit}}</div>
          <div class="wr f12 mt10">{{i.txt}}</div>
        </div>
      </div>

      <div class="ml30">
        <div class="tA mb30 f16">扫描二维码体验</div>
        <div class="flex">
          
          <div v-for='i in qrCode' :key="i" class="tA mr20 ml30">
            <img class="qrCode" :src="i.qr" alt="" srcset="">
            <div class="mt20 f14">{{i.tit}}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
    <div class="tA cf mt40 f16">
        <div class="mb10">联系电话：0512-88804100</div>
        <div>常熟市海虞北路45号（常熟世界贸易中心）A1918</div>
    </div>
    <div class="tA f12 mt40 cf"><a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_Blank" class="cf">版权所有© 2021点到安居(苏州)有限公司 苏ICP备16053084号-1</a></div>
</div>

<div class = 'foter cf' v-show="mobile">
  <div class="logoBox  mb30">
    <div class=" flexCent aIC">
      <img class="logo mr10 click" v-bind:src="logo" alt="" />
      <div class="f16 click ">{{cTxt}}</div>
    </div>
  </div>
  <div class="flexCent mb30">
    <div class="flex">
      <div v-for="i in fTxt" :key="i" class="mLR30">
        <div class="f16">{{i.tit}}</div>
        <div class="wr f12 mt10">{{i.txt}}</div>
      </div>
    </div>
    
  </div>
    <div class="">
      <div class="tA mb30 f16">扫描二维码体验</div>
      <div class="flexCent">
        <div v-for='i in qrCode' :key="i" class="tA mLR20">
          <img class="qrCode" :src="i.qr" alt="" srcset="">
          <div class="mt20 f14">{{i.tit}}</div>
        </div>
      </div>

    </div>
    <div class="tA cf mt40 f14">
        <div class="mb10">联系电话：0512-88804100</div>
        <div>常熟市海虞北路45号（常熟世界贸易中心）A1918</div>
    </div>
    <!-- <div class="tA f12 mt30 cf">苏ICP备16053084号-1</div> -->
    <div class="tA f12 mt60 cf"><a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_Blank" class="cf">版权所有© 2021点到安居(苏州)有限公司 苏ICP备16053084号-1</a></div>
</div>
</template>

<script>
  export default {
    data(){
      return{
        mobile: false,
        logo: require("../images/logo.png"),
        cTxt: '点到安居',
        fTxt:[
          {
            tit:'开放产品',
            txt:'物业端\n\n业主端\n\n后台管理\n\n增值版'
          },
          {
            tit:'帮助与支持',
            txt:'新手指南\n\n常见问题'
          },
        ],
        qrCode:[
          {
            tit:'智享未来生活',
            qr: require('../images/q.png')
          },
          {
            tit:'智慧物业管理服务平台',
            qr: require('../images/q1.png')
          }
        ]
      }
    },
    mounted(){
      let w = document.documentElement.offsetWidth || document.body.offsetWidth;
      console.log('w',w);
      if(w < 1000){
        this.mobile = true;
      }
    }

  }
</script>

<style>
  .foter{
    background: #121212;
    padding: 80px 0 40px 0;
  }

  .qrCode{
    width: 150px;
    height: 150px;
  }
</style>