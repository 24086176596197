<template>
    <div class="flexCent c0 f26 fw">{{topicName}}</div>
    <div class="flexCent mt10">
        <div class="line"></div>
    </div>
</template>

<script>

    export default {
        props: {
            topicName:{},
        },

    }
    // console.log(this.topicName);
</script>

<style>

</style>