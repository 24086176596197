<template>
    <!-- <topNav class="top" :style="style"></topNav> -->
    <swBanner :list = 'list'></swBanner>
    <div class="conBox">
        <div class="mt60">
        <topic :topicName = 'topicName'></topic>
        <div class="con">
            <conList :coList = 'coList'></conList>
        </div>
    </div>

        <div class="con mLRA" v-show="mobile == false">
            <div class="mb40">
                <topic :topicName = 'topicName6'></topic>
            </div>
            <div class="mb30 f20 tA">点到安居（苏州）有限公司于2013年正式成立，是一家专注于智慧物业管理服务平台研发、运营的科技企业，帮助物业管理企业提高管理水平和生产能力。点到信息一直秉持“以业主为中心”的服务理念，提供全方位，专业化的物业服务，为客户营造高品质的生活、工作环境，点到信息致力于精细化、规范化、专业化的服务，全力打造一个安全、舒适、文明、方便的社区环境。</div>
            <div class="aIC flexCent mb80">
                <img src="../images/icons/phone.png" class="icon1"  alt="" srcset="">
                <div class="f18 ml20">0512-88804100</div>
            </div>
        </div>

        <div class="con mLRA" v-show="mobile == false">
            <div class="mb60">
                <topic :topicName = 'topicName0'></topic>
            </div>
            <div style="box-shadow: 0px 1px 6px 0px rgba(16, 17, 19, 0.11); padding:40px">

                <div class="flexSB mt30" >
                    <div class="flex aIC click" :class='{active:(index == tBoxI )}' v-for="(i,index) in con" :key="i" @click='getIndex(index)'>
                        <img class="icon mr10" v-bind:src="i.icon" alt="" srcset="">
                        <div>
                            <div class="f16">{{i.tit}}</div>
                            <div class="f14  mt10" :class='{active:(index == tBoxI)}'>{{i.txt}}</div>
                        </div>
                    </div>
                </div>
                <div class="tBox mt30">
                    <div class="pR30">
                        <div class="f16">{{tBox[tBoxI].tit}}</div>
                        <div class="f14 mt10">{{tBox[tBoxI].txt}}</div>
                        <div class="f12 c1 mt10">{{tBox[tBoxI].more}}</div>
                    </div>
                </div>
            </div>
        </div>

        


        <div class="con1 mLRA" v-show="mobile">
            <div class="flexCent c0">领先的物业管理系统</div>
            <div class="flexCent mt10">
                <div class="line"></div>
            </div>
            <div class="flexCent">
                <div class="mt30" >
                    <div class="aIC click mb40" v-for="(i,index) in con" :key="i" @click='getIndex(index)'>
                        <div class="flexCent mb10">
                            <img class=" icon mr10" v-bind:src="i.icon" alt="" srcset="">   
                        </div>
                        <div>
                            <div class="flexCent f16">{{i.tit}}</div>
                            <div class="flexCent f14  mt10">{{i.txt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="con1 mLRA" v-show="mobile">
            <div class="flexCent c0">领先的物业管理系统</div>
            <div class="flexCent mt10">
                <div class="line"></div>
            </div>
            <div class="flexCent">
                <div class="mt30" >
                    <div class="aIC click mb40" v-for="(i,index) in con" :key="i" @click='getIndex(index)'>
                        <div class="flexCent mb10">
                            <img class=" icon mr10" v-bind:src="i.icon" alt="" srcset="">   
                        </div>
                        <div>
                            <div class="flexCent f16">{{i.tit}}</div>
                            <div class="flexCent f14  mt10">{{i.txt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="prodect pTB60 mt30">
            <div class="">
                <topic :topicName = 'topicName5' class=""></topic>
            </div>
            <div class="con">
                <conList :coList = 'coList2'></conList>
                <conList :coList = 'coList3' ></conList>
                <conList :coList = 'coList5' ></conList>
            </div>
            <div class="flexCent">

                <div class="btn tA">
                    了解更多
                </div>
            </div>
        </div>

        <div class="mt50">
            <div class="">
                <topic :topicName = 'topicName2'></topic>
            </div>
            <div class="">
                <showImg1 :coList = 'aSlist'></showImg1>
                <showImg1 :coList = 'aSlist1'></showImg1>
            </div>
        </div>
    </div>

    <div class="prodect pTB40" v-show="mobile">
        <div class="flexCent cf">丰富的产品</div>
        <div class="flexCent mt10">
            <div class="line1"></div>
        </div>
        <div class="flexCent">
            <div class=" mt30 ">
                <div class="flex">
                    <div v-for="(i,index) in proBox" :key="index" class="cf pTboxM click" @click="pro(index)" :class="{active1:(index == currentTag)}">
                        <div class="f16 tA">{{i.tit}}</div>
                        <!-- <div class="f14 ml10 mt10 wr"  >{{i.txt}}</div> -->
                    </div>
                </div>
                <div>
                    <div class="detailM flex cf">
                        <div class="dBox">
                            <div class="f18">{{detail[currentTag].tit}}</div>
                            <div class="mt10 wr f14 ">{{detail[currentTag].txt}}</div>
                            <div class="f12 sbtnW mt10">免费获取</div>
                        </div>
                        <div style="">
                            <img class="dBimgM" :src="detail[currentTag].bg" alt="" srcset="">
                        </div>
                    </div>
                    <div class="goM mt20">
                        <div class="cf tA">低门槛轻松上手，一天内快速集成</div>
                    </div>
                </div>
        </div>
        </div>
    </div>

    <div class="prodect pTB40" v-show="mobile == false">
        <div class="mb60">
                <topic :topicName = 'topicName3'></topic>
        </div>
        <div class="flexCent mt30 ">
            <div class="mr20">
                <div v-for="(i,index) in proBox" :key="index" class="cf pTbox click" @click="pro(index)" :class="{active1:(index == currentTag)}">
                    <div class="f16">{{i.tit}}</div>
                    <div class="f14 ml10 mt10 wr"  >{{i.txt}}</div>
                </div>
            </div>
            <div>
                <div class="detail flex cf">
                    <div class="dBox">
                        <div class="f18">{{detail[currentTag].tit}}</div>
                        <div class="mt10 wr f14 ">{{detail[currentTag].txt}}</div>
                        <div class="f12 sbtnW mt10">免费获取</div>
                    </div>
                    <div style="">
                        <img class="dBimg" :src="detail[currentTag].bg" alt="" srcset="">
                    </div>
                </div>
                <div class="go mt20">
                    <div class="cf tA">低门槛轻松上手，一天内快速集成</div>
                </div>
            </div>
        </div>
    </div>

    <div class="help">
        <img class="rb" src="../images/rb.png" alt="" srcset="">
        <img class="rb1" src="../images/rb1.png" alt="" srcset="">
        <div class="con">
            <div class="mb60">
                <topic :topicName = 'topicName4'></topic>
            </div>
        </div>
        <div class="flexSB mt30 mb60 con" v-show="mobile == false">
            <div v-for="i in help" :key="i" class="tA mLR30">
                <div>
                    <img class="hIcon" :src="i.icon" alt="">
                </div>
                <div class="f18 mt10">{{i.tit}}</div>
                <div class="wr c1 f14 mt10">{{i.txt}}</div>
            </div>
        </div>
        <div class="flexSb mt30 mb30 con" v-show="mobile">
            <div >
                <div v-for="i in help" :key="i" class="tA mLR30">
                    <div>
                        <img class="hIcon" :src="i.icon" alt="">
                    </div>
                    <div class="f18 mt10">{{i.tit}}</div>
                    <div class="wr c1 f14 mt10">{{i.txt}}</div>
                </div>
            </div>
        </div>
        <!-- <div>
            <div class="con1">
                <div class="flexCent">联系我们</div>
                <div class="flexCent mt10">
                    <div class="line"></div>
                </div>
            </div>
            
        </div> -->
        <!-- <div class="mt40 con">
            <div class="f16 aIC flex newsBox mLRA">
                <img class="news" src="../images/icons/news.png" alt="" srcset="">
                <div class="ml4">资询</div>
            </div>
            <div class="row tA">
                <div class="col-md-6 sNew mLRA">
                    <div class="f18 c0 nowrap mt20">助力开发者“C位”出道 点到科技“AI创造营”成都首站告捷</div>
                    <div class="f14 c1 mt10 mb20">随着数字时代全面到来，开发者正成为社会进步的中坚力量，更是企业创新的动力和产业革命的灵魂，机器视觉、人脸识别、深度学习等各类AI技术则是开发者手中用以改变世界的实战利器。</div>
                    <div class="btn mLRA f12">查看更多</div>
                </div>
                <div class="col-md-6 sNew mLRA">
                    <div class="f18 c0 nowrap mt20">助力开发者“C位”出道 点到科技“AI创造营”成都首站告捷</div>
                    <div class="f14 c1 mt10 mb20">随着数字时代全面到来，开发者正成为社会进步的中坚力量，更是企业创新的动力和产业革命的灵魂，机器视觉、人脸识别、深度学习等各类AI技术则是开发者手中用以改变世界的实战利器。</div>
                    <div class="btn mLRA f12">查看更多</div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="iBanner">
        <div class="flexCent cf ">智能的物业管理系统，一键申请，立即体验</div>
        <div class="flexCent mt10 mb30">
            <div class="line1"></div>
        </div>
        <div class="flexCent mb30 fs0" v-show="mobile == false">
            <div class="flex aIC click mLR30"  v-for="i in con1" :key="i">
                <img class="icon1 mr10" v-bind:src="i.icon" alt="" srcset="">
                <div class="f16 cf">{{i.tit}}</div>
            </div>
        </div>
        <div class="sNew1 mLRA" v-show="mobile">
            <div class=" flexSB aIC click  mb10"  v-for="i in con1" :key="i">
                <img class="icon1 mr10" v-bind:src="i.icon" alt="" srcset="">
                <div class="f16 cf">{{i.tit}}</div>
            </div>
        </div>
        <div class="btn mLRA f12">查看更多</div>
    </div>

    <div class="mt60 mb60">
        <topic :topicName = 'topicName1'></topic>
        <div class="mt20">
            <showImg1 :coList = 'coList4'></showImg1>
        </div>
    <div class="btn mLRA">查看更多</div>
</div>

    <!-- <foter></foter> -->
    
</template>

<script>

    // import topNav from '../components/topNav';
    import topic from '../components/topic';
    import swBanner from '../components/swBanner';
    // import aSbox from '../components/appScen';
    import conList from '../components/conList.vue';
    import showImg1 from '../components/showImg1'
    // import foter from '../components/foter';
    export default {
        components: {
            // topNav,
            swBanner,
            // aSbox,
            conList,
            topic,
            showImg1,
            // foter,
        },
        data() {
            return{
                tBoxI:0,
                currentTag:0,
                mobile: false,
                topicName: '智慧社区解决方案',
                topicName0: '领先的物业管理系统',
                topicName1: '最新课程',
                topicName2: '应用场景',
                topicName3: '丰富的产品',
                topicName4: '助力生态伙伴 共赢未来',
                topicName5: '智慧物业服务管理系统',
                topicName6: '公司简介',

                coList:[
                    {
                        tit:'物业端解决方案',
                        txt:'为小区管理者提供物业费用收缴、业主报修、公告\n通知、数据管理、车场管理、人员出入管理等物业\n管理全场景解决方案。',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'业主端解决方案',
                        txt:'一键分派任务工单',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'更多应用场景',
                        txt:'为多个不同应用场景定制特定服务\n小区、工厂、商超、园区等物业场景\n针对不同场景有不同解决方案。',
                        icon: require('../images/icons/x.png')
                    },
                ],
                coList2:[
                    {
                        tit:'移动服务',
                        txt:'轻松接入微信公众号、小程序，向所有小区住户提供高效物业移动服务，即使不同地区也可统一接入，打造出领先物业服务品牌',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'数据管理',
                        txt:'小区、楼宇、房屋、住户、车辆、车位、账单等小区数据一站式管理，数据导入、查询、编辑更加简单。',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'费用催缴',
                        txt:'物业账单定期生成，自动推送到住户手机，无需线下催缴，即可完成缴费。更有预存款、积分等催缴手段，轻松提高物业费用收缴率。',
                        icon: require('../images/icons/x.png')
                    },
                ],
                coList3:[
                    {
                        tit:'出入管理',
                        txt:'微小区以扫码通行为核心，提供住户通行、访客通行、出门条、消息通知、 权限审核、记录查询、智能门禁、智能停车为一体的出入管理解决方案。',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'消息推送',
                        txt:'利用微信的消息推送能力，强化物业消息通知能力，帮助物业将各类通知推送至全小区或不同楼宇住户手机上，改变传统物业公告张贴方式。',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'客服中心',
                        txt:'帮助物业建立在线客服中心，改变物业传统报修服务流程，实现多客服高效协同，还可转接外部维修师傅，完整的服务轨迹、评价管理，助力打造星级物业服务。',
                        icon: require('../images/icons/x.png')
                    },
                ],
                coList5:[
                    {
                        tit:'移动办公',
                        txt:'帮助物业快速接入企业微信，与微信互通消息，预设打卡、审批等办公应用，内部通知不再遗漏，手机上也可操作微小区各项功能，助力物业全面实现移动办公。',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'智慧二维码',
                        txt:'智慧二维码可以替代传统纸质表单，高效收集和展示信息，可用于设备巡检、巡逻检查、商品展示、资产标识、人员评比、活动报名等多种场景。',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'报表统计',
                        txt:'通过对房屋、住户、欠费、收入等小区基础数据进行多维度统计，每日推送营收统计，充分反应小区管理运行水平，为管理者决策提供数据支撑。',
                        icon: require('../images/icons/x.png')
                    },
                ],
                con:[
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '物业管理',
                        txt: '物业管理、物业检测'
                    },
                    {
                        icon:require("../images/icons/car.png"),
                        tit: '车辆管理',
                        txt: '智能停车、车位管理'
                    },
                    {
                        icon:require("../images/icons/xj.png"),
                        tit: '巡更巡检',
                        txt: '智能巡更、智能巡检'
                    },
                    {
                        icon:require("../images/icons/lock.png"),
                        tit: '智能设备',
                        txt: '智能门禁、智能开关...'
                    },
                ],
                con1:[
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '免费产品不限期',
                    },
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '所有产品免费试用',
                    },
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '开放应用套件',
                    },
                ],
                tBox: [
                    {
                        tit:'物业管理',
                        txt:'基于统一、标准的物业主数据模型，极致科技自主研发数字化创新方案和产品，为物业企业提供物业管理信息化、数字化整体解决方案。',
                        more: '了解更多'
                    },
                    {
                        tit:'车辆管理',
                        txt:'实现停车位资源的实时更新、查询、预订与导航服务一体化，实现停车位资源利用率的最大化、停车场利润的最大化和车主停车服务的最优化。',
                        more: '了解更多'
                    },
                    {
                        tit:'巡更巡检',
                        txt:'巡检范围全覆盖支持在线或离线两种模式自由切换，不放过任何一个信号死角，实现品质巡检范围全覆盖',
                        more: '了解更多'
                    },
                    {
                        tit:'智能设备',
                        txt:'可以进行电梯控制、车辆进出控制，物业消防监控、保安巡检管理、餐饮收费管理等，真正实现区域内一卡智能管理。',
                        more: '了解更多'
                    }
                ],
                proBox: [
                        {
                            tit:'免费版',
                            txt:'测试版物业管理系统',
                        },
                        {
                            tit:'增值版',
                            txt:'物业管理系统\n业主端管理系统  \n智慧停车\n智能设备\n...',
                        },
                        {
                            tit:'应用软件',
                            txt:'物业端\n业主端\n后台管理\n...',
                        },
                    ],
                detail:[
                    {
                        tit:'智慧物业免费版',
                        txt:'测试版物业管理系统',
                        bg: require('../images/dBg.png')
                    },
                    {
                        tit:'智慧物业增值版',
                        txt:'物业管理系统\n业主端管理系统\n智慧停车\n智能设备\n...',
                        bg: require('../images/dBg1.png')
                    },
                    {
                        tit:'智慧物业应用软件',
                        txt:'物业端\n业主端\n后台管理\n...',
                        bg: require('../images/dBg2.png')
                    },
                ],
                help:[
                    {
                        tit:'技术支持',
                        txt:'专属的定期培训、技术支持、及新产品内测\n体验还将有机会参与点到技术沙龙',
                        icon: require('../images/icons/h.png')
                    },
                    {
                        tit:'品牌共建',
                        txt:'获得点到科技的品牌赋能推广及专有的营销渠道\n推广助力您创造品牌价值。',
                        icon: require('../images/icons/h1.png')
                    },
                    {
                        tit:'成就客户',
                        txt:'获得定制化需求支持及入驻平台展会机会\n引领行业升级，推进智能生态的建设与发展',
                        icon: require('../images/icons/h2.png')
                    },
                ],
                coList4:[
                    {   
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                    {
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                    {
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                ],
                aSlist:[
                    {
                        icon: require('../images/c.png'),
                        tit: '小区'
                    },
                    {
                        icon: require('../images/f.png'),
                        tit: '工厂'
                    },
                    {
                        icon: require('../images/mark.png'),
                        tit: '商超'
                    }, 
                ],
                aSlist1:[
                    {
                        icon: require('../images/o.png'),
                        tit: '写字楼'
                    },
                    {
                        icon: require('../images/ho.png'),
                        tit: '酒店'
                    },
                    {
                        icon: require('../images/hos.png'),
                        tit: '医院'
                    },
                ]
            }

        },
        setup(){
            return{
                list:[
                    {img:require("../images/first.png")},
                    {img:require("../images/second.png")},
                    
                ]
            }
        },
        methods:{
            getIndex(e){
                console.log(this.tBoxI,e);
                this.tBoxI = e
                console.log(this.tBoxI);
            },
            pro(e){
                console.log(e);
                this.currentTag = e
                console.log(this.currentTag);
            },
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }
    }


</script>

<style>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
    .top{
        position: fixed;
        top: 0;
        z-index: 9999;
    }
    .conBox{
        width: 100%;
        /* background: #EBF0F6; */
        padding: 60px 0;
    }
    .con{
        /* background: #fff; */
        /* width: 900px; */
        margin: 0 200px;
        border-radius: 10px;
        padding: 0 40px;
    }
    .con1{
        background: #fff;
        /* width: 900px; */
        border-radius: 10px;
        padding: 40px 40px;
    }
    .bF{
        background: #fff;
    }

    .icon{
        width: 50px;
        height: 50px;
    }

    .tBox{
        width: 100%;
        background: #EBF0F6;
        border-radius: 10px;
        padding: 20px 0;
    }

    .active{
        color: #4988FD;
    }
    .prodect{
        background: #f6f6f6;
    }

    .pTbox{
        background: #5D96FF;
        padding: 34px;
        width: 220px;
    }
    .pTboxM{
        background: #5D96FF;
        padding: 40px 0;
        width: 120px;

    }
    .active1{
        background: #3D76E2;
    }
    .detail{
        position: relative;
        width: 750px;
        height: 340px;
        background: #3661DF;
        padding: 30px;
    }
    .detailM{
        position: relative;
        width: 300px;
        height: 240px;
        background: #3661DF;
        padding: 30px;
    }
    .dBox{
        margin: auto 0;
    }
    .dBimg{
        width: 350px;
        height: 310px;
        position: absolute;
        top: 10%;
        right: 4%;
    }
    .dBimgM{
        width: 200px;
        height: 180px;
        position: absolute;
        top: 20%;
        right: 4%;
    }


    .go{
        background: #4988FD;
        /* width: 780px; */
        height: 70px;
        line-height: 70px;
    }
    .goM{
        background: #4988FD;
        width: 360px;
        height: 70px;
        line-height: 70px;
    }

    .help{
        position: relative;
        padding: 30px 0;
    }

    .rb{
        position: absolute;
        top:0;
        left: 0;
    }
    .rb1{
        position: absolute;
        bottom:0;
        right: 0;
    }
    .hIcon{
        width: 160px;
        height: 160px;
    }
    .newsBox{
        width: 50%;
    }

    .news{
        width: 14px;
        height: 18px;
    }

    .sNew{
        width: 48%;
    }
    .sNew1{
        width: 40%;
    }
    .iBanner{
        width: 100%;
        padding: 40px 0;
        background: #000;
    }
    .icon1{
        width: 16px;
        height: 16px;
    }

</style>