<template>
  <div class="topNav flexSB aIC" v-bind:style="{color:color,background:bgColor,boxShadow: boxShadow}">
    <div class=" logoBox flex aIC ml80" @click="goHome">
      <img class="logo mr10 click" v-bind:src="logo" alt="" />
      <!-- <img class="logo" src="../images/logo.png" alt="" /> -->
      <div class="f16 click">{{cTxt}}</div>
    </div>
    
    <div class="flex aIC mr80">
      <!-- :class="{active:(index == currentTag)}" -->
      <div class="f14 mr20 nav click"  v-for=" (i,index) in nav " :key="i" @click="jump(index)" >{{i}}</div>
      <!-- <a class="btn f12" href="http://mu.ddaop.com" target="_blank" rel="noopener">{{btn}}</a> -->
    </div>
  </div> 
  

</template>

<script>
  export default {
    name: "topNav",
    data(){
      return{
        mobile:false,
        currentTag: 0,
        color:"",
        bgColor:"",
        boxShadow: "",
        logo: require("../images/logo.png"),
        cTxt: '点到安居',
        nav: [
          '首页',
          '开放产品',
          // '核心技术',
          '应用方案',
          // '客户案例',
          '公开课',
          '定价',
          '产业链市场',
          // '新闻资讯',
          '新闻资讯',
          '用户登陆',
          // 'test'
          // '帮助与支持',
          ],
          btn:'开发者中心'
      }
    },
    mounted() {
			window.addEventListener("scroll", this.windowScroll);
       //监听页面滚动
      let w = document.documentElement.offsetWidth || document.body.offsetWidth;
        console.log('w',w);
        if(w < 1000){
          this.mobile = true;
        }
        console.log(this.mobile);
		},
    methods: {
			windowScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				// this.opacity = Math.abs(Math.round(scrollTop)) / 250;
        console.log(scrollTop);
        if (scrollTop > 200) {
          // console.log('change color');
          this.color = "#000"
          this.bgColor = '#fff'
          this.boxShadow = '0px 1px 6px 0px rgba(0, 0, 0, 0.11)';
        }else if (scrollTop < 200) {
          this.color="#fff"
          this.bgColor = ''
          this.boxShadow = ''
        }
			},
      goHome(){this.$router.push('/');},

      jump(e){
        console.log(e);
        this.currentTag = e
        if (e == 0) {
          this.$router.push('/');
        }else if(e == 1){
          this.$router.push('/product');
        }else if(e == 2){
          this.$router.push('/aS');
        }else if(e == 3){
          this.$router.push('/course');
        }else if (e == 4) {
          this.$router.push('/price');
        }else if(e == 5){
          this.$router.push('/mark');
        }else if(e == 6){
          this.$router.push('/news');
        }else if(e == 7){
          this.$router.push('/logIn');
        }
      }
		},


		// destroyed() {
		// 	window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
		// }

  }
</script>

<style>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
  *{
    /* color: #fff; */
  }

  a{
    text-decoration:none;
  }
 
  .topNav{
    width: 100%;
    height: 80px;
    color: #fff;
    /* background: #909090; */
    /* padding: 0 300px; */
  }
  /* .logoBox{

  } */
  .logoBox img{
    width: 30px;
    height: 30px;
  }

  .nav:hover{
    color: #4988FD;
  }

  .btn{
    width: 88px;
    border: #4988FD 1px solid;
    text-align: center;
    padding: 4px 0;
    border-radius: 2px;
    color: #4988FD;
    background: #4988fd18;
  }

  .btn:hover{
    background: #4988FD;
    color: #fff;
    
  }




</style>