<template>
    <div class="flexSB mt30 mb30 con" v-show="mobile == false">
        <div v-for="i in coList" :key="i" class="tA mLR30 colBox">
            <div>
                <img :src="i.icon" alt="">
            </div>
            <div class="f18 mt10">{{i.tit}}</div>
        </div>
    </div>

    <div class="flexSB mt30 mb30 colBox" v-show="mobile">
        <div >
            <div v-for="i in coList" :key="i" class="tA mLR30 colBox">
                <div>
                    <img :src="i.icon" alt="">
                </div>
                <div class="f18 mt10">{{i.tit}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            coList: {}
        },
        data(){
            return{
                mobile:false,
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }
    }
</script>

<style>
@import '../assets/css/font.css';
    @import '../assets/css/layout.css';

    .colBox{
        /* width: 400px; */
        /* margin: auto 200px; */
        padding: 20px 0;
    }
    .colBox img{
        width: 350px;
    }
    /* .colBox:hover{
        box-shadow: 0px 1px 6px 0px rgba(16, 17, 19, 0.11);
    } */
</style>