<template>
    <div class="topNavM pTB20" v-bind:style="{color:color,background:bgColor,boxShadow: boxShadow,height:height,}">
        <div class="flexSB aIC">
            <div class=" logoBox flex aIC ml20" @click="goHome">
                <img class="logo mr10 click" v-bind:src="logo" alt="" />
                <!-- <img class="logo" src="../images/logo.png" alt="" /> -->
                <div class="f16 click">{{cTxt}}M</div>
            </div>
                
            <div class="mr20">
                <img class="navIcon" src="../images/icons/more.png" alt="" srcset="" @click="showNav" v-show="open == false">
                <img class="navIcon" src="../images/icons/close.png" alt="" srcset="" @click="closeNav" v-show="open">
            </div>
            
        </div>
        <div class="navT tA" v-show="open">
            <div class="line3 mt20 mb20"></div>
            <div class="f16 mb20 nav click"  v-for=" (i,index) in nav " :key="i" @click="jump(index)" >
                {{i}}
                <div class="line3 mt20 mb20"></div>
            </div>
            <a class="btn f12 mt20" href="http://mu.ddaop.com" target="_blank" rel="noopener">{{btn}}</a>
        </div>
    </div> 



</template>

<script>
  export default {
    name: "topNav",
    data(){
      return{
        mobile:false,
        open:false,
        currentTag: 0,
        color:"",
        bgColor:"",
        boxShadow: "",
        height: "",
        logo: require("../images/logo.png"),
        cTxt: '点到安居',
        nav: [
          '首页',
          '开放产品',
          // '核心技术',
          '应用方案',
          // '客户案例',
          '公开课',
          '产业链市场',
          // '新闻资讯',
          '新闻资讯',
          // '帮助与支持',
          ],
          btn:'开发者中心'
      }
    },
    mounted() {
			window.addEventListener("scroll", this.windowScroll);
       //监听页面滚动
      let w = document.documentElement.offsetWidth || document.body.offsetWidth;
        console.log('w',w);
        if(w < 1000){
          this.mobile = true;
        }
        console.log(this.mobile);
		},
    methods: {
		// windowScroll() {
		// 		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		// 		// this.opacity = Math.abs(Math.round(scrollTop)) / 250;
        // console.log(scrollTop);
        // if (scrollTop > 200) {
        //   // console.log('change color');
        //   this.color = "#000"
        //   this.bgColor = '#fff'
        //   this.boxShadow = '0px 1px 6px 0px rgba(0, 0, 0, 0.11)';
        //   this.height = '100%'
        // }else if (scrollTop < 200) {
        //   this.color="#fff"
        //   this.bgColor = ''
        //   this.boxShadow = ''
        // }
		// 	},
      goHome(){this.$router.push('/');},
      showNav(){
          this.open = true
          this.color = "#000"
          this.bgColor = '#fff'
          this.boxShadow = '0px 1px 6px 0px rgba(0, 0, 0, 0.11)';
      },
      closeNav(){
          this.open = false
      },

      jump(e){
        console.log(e);
        this.currentTag = e
        if (e == 0) {
          this.$router.push('/');
          this.open = false
        }else if(e == 1){
          this.$router.push('/product');
          this.open = false
        }else if(e == 2){
          this.$router.push('/aS');
          this.open = false
        }else if(e == 3){
          this.$router.push('/course');
          this.open = false
        }else if(e == 4){
          this.$router.push('/mark');
          this.open = false
        }else if(e == 5){
          this.$router.push('/news');
          this.open = false
        }
      }
		},


		// destroyed() {
		// 	window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
		// }

  }
</script>

<style>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
  *{
    /* color: #fff; */
  }

  a{
    text-decoration:none;
  }
 
  .topNavM{
    width: 100%;
    /* height: 80px; */
    
    /* color: #fff; */
    background: #fff;
    /* padding: 0 300px; */
  }
  /* .logoBox{

  } */
  .logoBox img{
    width: 30px;
    height: 30px;
  }

  .nav:hover{
    color: #4988FD;
  }

  .btn{
    width: 88px;
    border: #4988FD 1px solid;
    text-align: center;
    padding: 4px 0;
    border-radius: 2px;
    color: #4988FD;
    background: #4988fd18;
  }

  .btn:hover{
    background: #4988FD;
    color: #fff;
    
  }
  .navIcon{
      width: 20px;
  }




</style>