<template>
    <div class="flexSB mt40 mb40 fs0" v-show="mobile == false">
        <div v-for="i in coList" :key="i" class="tA mLR30 colBox fs0">
            <div>
                <img class="icons" :src="i.icon" alt="">
            </div>
            <div class="f18 mt10">{{i.tit}}</div>
            <div class="wr c1 f14 mt10">{{i.txt}}</div>
        </div>
        
    </div>

    <div class="flexSB" v-show="mobile">
        <div class="" >
            <div v-for="i in coList" :key="i" class="tA  colBox">
                <div>
                    <img class="icons" :src="i.icon" alt="">
                </div>
                <div class="f18 mt10">{{i.tit}}</div>
                <div class="wr c1 f14 mt10">{{i.txt}}</div>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        props:{
            coList: {}
        },
        data() {
            return{
                mobile: false,
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }

 
    }
</script>

<style scoped>
    .icons{
        width: 40px;
        height: 40px;
    }
    .colBox{
        background: #fff;
        width: 310px;   
        padding: 40px 30px;
        box-shadow: 0px 1px 6px 0px rgba(16, 17, 19, 0.11);
    }
    .colBox:hover{
        border: #5983ff 1px solid;
        box-shadow: 0px 1px 6px 0px rgba(90, 145, 255, 0.11);
    }
</style>